import React from "react";
import PropTypes from "prop-types";

import styles from "./Video.module.css";

const Video = ({ videoSrcURL, videoTitle, children, className, ...others }) => {
  return (
    <div className={`${styles.video} ${className}`}>
      <iframe
        src={videoSrcURL}
        title={videoTitle}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        frameBorder="0"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowFullScreen
        {...others}
      />
    </div>
  );
};

Video.defaultProps = {
  className: "",
  videoSrcURL: "",
  videoTitle: "",
};

Video.propsTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Video;
