import React, { Fragment } from "react";

import { graphql, useStaticQuery } from "gatsby";

import styles from "./Feedbacks.module.css";
import Title from "../../../elements/Title";
import Container from "../../../blocks/Container";
import Text from "../../../elements/Text";
import Image from "../../../elements/Image";
import useInfiniteScroll from "react-infinite-scroll-hook";
import Button from '../../../elements/Button';

const Feedbacks = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/new-feedbacks.md/" } }
      ) {
        edges {
          node {
            frontmatter {
              description
              testimonials {
                title
                title2
                text
                subtitle
                previewImage
                href
              }
            }
          }
        }
      }
    }
  `);

  const [infiniteLayout, setInfiniteLayout] = React.useState({
    hasNextPage: true,
    loading: false,
    step: 1,
  });

  const {
    description,
    testimonials,
  } = data.allMarkdownRemark.edges[0].node.frontmatter;

  const loadMore = () => {
    setInfiniteLayout({ ...infiniteLayout, loading: true });
    setTimeout(() => {
      setInfiniteLayout({
        ...infiniteLayout,
        step: infiniteLayout.step + 1,
        loading: false,
        hasNextPage: infiniteLayout.step + 1 < 5,
      });
    }, [800]);
  };

  const [sentryRef] = useInfiniteScroll({
    loading: infiniteLayout.loading,
    hasNextPage: infiniteLayout.hasNextPage,
    onLoadMore: loadMore,
    rootMargin: "0px 0px 400px 0px",
  });

  const ImageCard = ({ px, py, children, className, style }) => {
    return (
      <div
        className={`${styles.feedbacks__imageCard} ${className}`}
        style={{ ...style, padding: `${py}px ${px}px` }}
      >
        {children}
      </div>
    );
  };

  return (
    <div className={styles.feedbacks}>
      <Title className={styles.feedbacks__topTitle}>What Customers Are Saying</Title>
      <Container type="mobile" className="d-flex justify-content-center">
        <Text className={styles.feedbacks__subtitleContainer}>
          {description}
        </Text>
      </Container>
      <Container type="mobile" className="d-flex justify-content-center testimonials-cta">
            <Button
              type="action"
              className={styles.testimonials__testimonialsCta}
              href="https://start.deadlinefunnel.com/create-monthly"
              target="_blank"
              >
              Try It Free
            </Button>
          </Container>
      <Container type="big">
        <Container type="mobile">
          {infiniteLayout.step > 0 && (
            <div className={styles.feedbacks__rows}>
              <div className={styles.feedbacks__firstRowLeft}>
                <ImageCard className={styles.feedbacks__feedback1}>
                  <Image name="sarah-feedback.png" />
                </ImageCard>
                <ImageCard
                  className={`${styles.feedbacks__mobileShow} ${styles.feedbacks__feedback2} mt-3`}
                >
                  <Image name="rich-feedback.png" />
                </ImageCard>
                <ImageCard className={`${styles.feedbacks__feedback3} mt-3`}>
                  <Image name="melissa-feedback.png" />
                </ImageCard>
                <ImageCard
                  className={`${styles.feedbacks__mobileShow} ${styles.feedbacks__feedback4} mt-3`}
                >
                  <Image name="mobile-margo-header.png" />
                  <p
                    className={
                      styles.feedbacks__mobileFeedbackWhiteContentSmall
                    }
                  >
                    You guys freaking rock, and you help my clients earn a
                    living with their online businesses. So thankful for you and
                    your team! That is all.
                  </p>
                  <Image name="mobile-margo-footer.png" />
                </ImageCard>
              </div>
              <div className={styles.feedbacks__firstRowRight}>
                <ImageCard className={styles.feedbacks__feedback2}>
                  <Image name="rich-feedback.png" />
                </ImageCard>
                <ImageCard className={`${styles.feedbacks__feedback4} mt-3`}>
                  <Image name="margo-feedback.png" />
                </ImageCard>
              </div>
            </div>
          )}
          {infiniteLayout.step > 1 && (
            <div className={`${styles.feedbacks__rows} mt-3`}>
              <div className={styles.feedbacks__secondRowLeft}>
                <ImageCard
                  className={`${styles.feedbacks__feedback5} ${styles.feedbacks__mobileHidden}`}
                >
                  <Image name="tracy-feedback.png" />
                </ImageCard>
                <ImageCard
                  className={`${styles.feedbacks__feedback5} ${styles.feedbacks__mobileShow}`}
                >
                  <Image name="mobile-tracy-header.png" />
                  <p
                    className={
                      styles.feedbacks__mobileFeedbackWhiteContentMedium
                    }
                  >
                    Hi Jack, How long do we get access to the repays if we
                    enroll? I have always loved reading your emails. You are one
                    of the best out there and I hate reading. I can listen all
                    day long to so many popular marketing podcasters, but can’t
                    deal with their emails, but when you write something I stop
                    and read every word. Thank you! Tracy
                  </p>
                </ImageCard>
                <ImageCard
                  className={`${styles.feedbacks__mobileShow} ${styles.feedbacks__feedback6} mt-3`}
                >
                  <Image name="skilled-feedback.png" />
                </ImageCard>
                <ImageCard
                  className={`${styles.feedbacks__feedback7} ${styles.feedbacks__mobileHidden} mt-3`}
                >
                  <Image name="hope-feedback.png" />
                </ImageCard>
                <ImageCard
                  className={`${styles.feedbacks__feedback7} ${styles.feedbacks__mobileShow} mt-3`}
                >
                  <Image
                    name="mobile-hope-header.png"
                    className={styles.feedbacks__mobileHopeHeader}
                  />
                  <p
                    className={
                      styles.feedbacks__mobileFeedbackWhiteContentMedium
                    }
                  >
                    This is really awesome to hear that you are about to offer
                    it to the general public Jack. All strategic entrepreneurs
                    need this! Below is my testimonial on why I love Stealth
                    Selling: I am just getting started in the online world and I
                    have heard so many times that - facts tell and stories sell.
                    But the question then became how do I use stories to sell
                    while still providing value to my audience without feeling
                    like I was pitching something all the time...Enter Stealth
                    Selling! This course is gold! Before Stealth Selling, I was
                    struggling with how to provide value while selling at the
                    same time. The strategies and frameworks that you laid out
                    in the course have helped me to finally start building my
                    story bank, build trust with my audience and send email
                    without feeling guilty because I know I’m providing value
                    even when I am presenting an offer in an email. This course
                    is like a story selling manual for entrepreneurs! It should
                    honestly be more expensive. Thank you so much Jack!
                    <br />
                    - Bernard
                    <br />
                    Thanks again Jack!
                  </p>
                </ImageCard>
                <ImageCard
                  className={`${styles.feedbacks__mobileShow} ${styles.feedbacks__feedback8} mt-3`}
                >
                  <Image name="ronnie-feedback.png" />
                </ImageCard>
                <ImageCard className={`${styles.feedbacks__feedback9} mt-3`}>
                  <Image name="brandon-feedback.png" />
                </ImageCard>
                <ImageCard
                  className={`${styles.feedbacks__mobileShow} ${styles.feedbacks__feedback10} mt-3`}
                >
                  <Image name="jane-feedback.png" />
                </ImageCard>
                <ImageCard className={`${styles.feedbacks__feedback11} mt-3`}>
                  <Image name="andre-feedback.png" />
                </ImageCard>
                <ImageCard
                  className={`${styles.feedbacks__mobileShow} ${styles.feedbacks__feedback12} mt-3`}
                >
                  <Image name="kory-feedback.png" />
                </ImageCard>
                <ImageCard className={`${styles.feedbacks__feedback13} mt-3`}>
                  <Image name="jon-feedback.png" />
                </ImageCard>
                <ImageCard
                  className={`${styles.feedbacks__mobileShow} ${styles.feedbacks__feedback14} mt-3`}
                >
                  <Image name="janice-feedback.png" />
                </ImageCard>
              </div>
              <div className={styles.feedbacks__secondRowRight}>
                <ImageCard className={`${styles.feedbacks__feedback6}`}>
                  <Image name="skilled-feedback.png" />
                </ImageCard>
                <ImageCard className={`${styles.feedbacks__feedback8} mt-3`}>
                  <Image name="ronnie-feedback.png" />
                </ImageCard>
                <ImageCard className={`${styles.feedbacks__feedback10} mt-3`}>
                  <Image name="jane-feedback.png" />
                </ImageCard>
                <ImageCard className={`${styles.feedbacks__feedback12} mt-4`}>
                  <Image name="kory-feedback.png" />
                </ImageCard>
                <ImageCard className={`${styles.feedbacks__feedback14} mt-4`}>
                  <Image name="janice-feedback.png" />
                </ImageCard>
              </div>
            </div>
          )}
          {infiniteLayout.step > 2 && (
            <>
              <div className={`${styles.feedbacks__rows} mt-0 mt-md-3`}>
                <div className="col-12 px-2">
                  <ImageCard
                    className={`${styles.feedbacks__feedback15} ${styles.feedbacks__mobileHidden}`}
                  >
                    <Image name="jonathan-feedback.png" />
                  </ImageCard>
                </div>
              </div>
              <div className={`${styles.feedbacks__rows} mt-3`}>
                <div className="col-12 px-2">
                  <ImageCard
                    className={`${styles.feedbacks__feedback16} ${styles.feedbacks__mobileHidden}`}
                  >
                    <Image name="karo-feedback.png" />
                  </ImageCard>
                  <ImageCard
                    className={`${styles.feedbacks__feedback16} ${styles.feedbacks__mobileShow}`}
                  >
                    <Image name="mobile-karo-header.png" />
                    <p
                      className={
                        styles.feedbacks__mobileFeedbackWhiteContentMedium
                      }
                    >
                      Hey Anthony, Thanks a lot for the bonuses :))) If you
                      liked my words, of course, you can use them as a
                      testimonial when I have all my funnels in place ;) I LOVE
                      what Deadline Funnel is offering to my business: so many
                      possibilities of getting more conversions! Although I have
                      been with DF for a while, I didn’t have time to get to
                      know this tool in depth (yet!). This is my goal for this
                      Spring: to go through your knowledge base, to understand
                      how to use it, to be up to date with all your new
                      integrations, understand all the metrics, etc. I will
                      study them in my “morning sessions” of work! :)
                      <br />
                      Bests,
                      <br />
                      Karo
                    </p>
                  </ImageCard>
                </div>
              </div>
            </>
          )}
          {infiniteLayout.step > 3 && (
            <>
              <div className={`${styles.feedbacks__rows} mt-3`}>
                <div className={styles.feedbacks__thirdRowLeft}>
                  <ImageCard
                    px={12}
                    py={16.49}
                    className={`${styles.feedbacks__mobileShow} ${styles.feedbacks__feedback18}`}
                  >
                    <Image name="frank-feedback.png" />
                  </ImageCard>
                  <ImageCard
                    className="mt-3"
                    px={17.5}
                    py={31.5}
                    className={`${styles.feedbacks__mobileShow} ${styles.feedbacks__feedback19} mt-3`}
                  >
                    <Image name="mobile-sarah-turner-header.png" />
                    <Image name="mobile-sarah-turner-content.png" />
                  </ImageCard>
                  <ImageCard
                    className={`${styles.feedbacks__feedback17} mt-3 mt-sm-0`}
                  >
                    <Image name="belinda-feedback.png" />
                  </ImageCard>
                  <ImageCard
                    className={`${styles.feedbacks__mobileShow} ${styles.feedbacks__feedback24} mt-3`}
                  >
                    <Image name="john-feedback.png" />
                  </ImageCard>
                </div>
                <div className={styles.feedbacks__thirdRowRight}>
                  <ImageCard className={`${styles.feedbacks__feedback18}`}>
                    <Image name="frank-feedback.png" />
                  </ImageCard>
                  <ImageCard className={`${styles.feedbacks__feedback19} mt-3`}>
                    <Image name="sarah-turner-feedback.png" />
                  </ImageCard>
                </div>
              </div>
              <div
                className={`${styles.feedbacks__rows} ${styles.feedbacks__mobileHidden} mt-3`}
              >
                <div className="col-12 px-2">
                  <ImageCard className={`${styles.feedbacks__feedback20}`}>
                    <Image name="holly-feedback.png" />
                  </ImageCard>
                </div>
              </div>
              <div
                className={`${styles.feedbacks__rows} ${styles.feedbacks__mobileHidden} mt-3`}
              >
                <div className="col-12 px-2">
                  <ImageCard className={`${styles.feedbacks__feedback21}`}>
                    <Image name="david-feedback.png" />
                  </ImageCard>
                </div>
              </div>
              <div className={`${styles.feedbacks__rows} mt-3`}>
                <div className="col-12 px-2">
                  <ImageCard
                    className={`${styles.feedbacks__feedback22} ${styles.feedbacks__mobileHidden}`}
                  >
                    <Image name="thomas-feedback.png" />
                  </ImageCard>
                  <ImageCard
                    className={`${styles.feedbacks__feedback22} ${styles.feedbacks__mobileShow}`}
                  >
                    <Image name="mobile-thomas-header.png" />
                    <Image name="mobile-thomas-content.png" />
                  </ImageCard>
                  <ImageCard
                    className={`${styles.feedbacks__feedback23} ${styles.feedbacks__mobileShow} mt-3`}
                  >
                    <Image name="mobile-rick-header.png" />
                    <Image name="mobile-rick-content.png" />
                  </ImageCard>
                  <ImageCard
                    className={`${styles.feedbacks__feedback20} ${styles.feedbacks__mobileShow} mt-3`}
                  >
                    <Image name="mobile-holly-header.png" />
                    <p
                      className={
                        styles.feedbacks__mobileFeedbackWhiteContentMedium
                      }
                    >
                      Thank you!
                      <br />
                      <br />
                      Testimonial
                      <br />
                      <br />
                      “I highly recommend Deadline Funnel Concierge Setup! Their
                      team members are patient, knowledgable and always go the
                      extra mile. If you want to move your business forward with
                      a fantastic funnel, get the concierge set up service
                      TODAY!”
                    </p>
                  </ImageCard>
                </div>
              </div>
              <div className={`${styles.feedbacks__rows} mt-3`}>
                <div className={styles.feedbacks__fourthRowLeft}>
                  <ImageCard
                    className={`${styles.feedbacks__feedback23} ${styles.feedbacks__mobileHidden}`}
                  >
                    <Image name="rick-feedback.png" />
                  </ImageCard>
                  <ImageCard
                    className={`${styles.feedbacks__feedback24} ${styles.feedbacks__mobileShow}`}
                  >
                    <Image name="john-feedback.png" />
                  </ImageCard>
                </div>
                <div className={styles.feedbacks__fourthRowRight}>
                  <ImageCard className={`${styles.feedbacks__feedback24}`}>
                    <Image name="john-feedback.png" />
                  </ImageCard>
                </div>
              </div>
              <div className={`${styles.feedbacks__rows} mt-3`}>
                <div className="col-12 px-2">
                  <ImageCard
                    className={`${styles.feedbacks__feedback25} ${styles.feedbacks__mobileHidden}`}
                  >
                    <Image name="mitch-feedback.png" />
                  </ImageCard>
                  <ImageCard
                    className={`${styles.feedbacks__feedback25} ${styles.feedbacks__mobileShow}`}
                  >
                    <Image name="mobile-mitch-header.png" />
                    <Image name="mobile-mitch-content.png" />
                  </ImageCard>
                  <ImageCard
                    className={`${styles.feedbacks__feedback21} ${styles.feedbacks__mobileShow} mt-3`}
                  >
                    <Image name="mobile-david-header.png" />
                    <p
                      className={
                        styles.feedbacks__mobileFeedbackWhiteContentMedium
                      }
                      style={{ lineHeight: "17.95px" }}
                    >
                      That would be fantastic. Thanks for such an amazing tool.
                      It’s made such a difference in my business in the last few
                      years!
                    </p>
                  </ImageCard>
                  <ImageCard
                    className={`${styles.feedbacks__mobileShow} ${styles.feedbacks__feedback26} mt-3`}
                  >
                    <Image name="mobile-george-content.png" />
                  </ImageCard>
                </div>
              </div>
              <div
                className={`${styles.feedbacks__rows} ${styles.feedbacks__mobileHidden} mt-3`}
              >
                <div className="col-12 px-2">
                  <ImageCard className={`${styles.feedbacks__feedback26}`}>
                    <Image name="george-feedback.png" />
                  </ImageCard>
                </div>
              </div>
            </>
          )}
          {(infiniteLayout.loading || infiniteLayout.hasNextPage) && (
            <div ref={sentryRef} className={styles.feedbacks__loading}>
              Loading...
            </div>
          )}
        </Container>
      </Container>
      <Image
        name="case-studies-left-plant"
        className={`${styles.feedbacks__bottomLeftPlant}`}
      />
      <Image
        name="case-studies-right-plant"
        className={`${styles.feedbacks__bottomRightPlant}`}
      />
    </div>
  );
};

export default Feedbacks;
