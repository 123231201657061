import React from "react";
import styles from "./CaseStudiesHeader.module.css";
import Title from "../../../elements/Title";
import Video from "../../../elements/Video";
import Container from "../../../blocks/Container";
import FullWidthRow from "../../../blocks/FullWidthRow";
import Image from "../../../elements/Image";
import { useStaticQuery } from "gatsby";

const CaseStudiesHeader = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/new-case-studies-header.md/" } }
      ) {
        edges {
          node {
            frontmatter {
              title
              videoURL
            }
          }
        }
      }
    }
  `);

  const { title, videoURL } = data.allMarkdownRemark.edges[0].node.frontmatter;

  return (
    <div className={styles.caseStudiesHeader}>
      <Container>
        <Container type="mobile">
          <FullWidthRow className="mb-4">
            <Title type="white">
              <div dangerouslySetInnerHTML={{ __html: title }}></div>
            </Title>
          </FullWidthRow>
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-12 order-xl-1 order-lg-0 order-0 d-flex flex-column align-items-md-center h-100">
              <div className={`${styles.caseStudiesHeader__video}`}>
                <div className={styles.caseStudiesHeader__videoPreview}>
                  <Video
                    videoSrcURL={videoURL}
                    videoTitle="Testimonial"
                    width="100%"
                    height="100%"
                  />
                </div>
              </div>
              <Image
                name="case-studies-woman"
                className={`${styles.caseStudiesHeader__woman}`}
              />
              <Image
                name="case-studies-plant"
                className={`${styles.caseStudiesHeader__plant}`}
              />
            </div>
          </div>
        </Container>
      </Container>
      <div className={styles.caseStudiesHeader__clouds} />
      <div className={styles.caseStudiesHeader__mobileCloudsMask} />

    </div>
  );
};

export default CaseStudiesHeader;
