import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import styles from './CaseStudiesContent.module.css';

import Container from '../../../blocks/Container';
import Image from '../../../elements/Image';
import Text from '../../../elements/Text';
import LearnMoreLink from '../../../elements/LearnMoreLink';
import Testimonials from './Testimonials';
import Title from '../../../elements/Title';
import Button from '../../../elements/Button';


const CaseStudiesContent = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/new-case-studies-list.md/" } }) {
        edges {
          node {
            frontmatter {
              description
              caseStudies {
                image
                author
                occupation
                description
                href
              }
            }
          }
        }
      }
    }
  `);

  const { caseStudies, description } = data.allMarkdownRemark.edges[0].node.frontmatter;

  return (
    <div className={styles.caseStudiesContent}>
      
      <Container type="big">
        <Container type="mobile">
          <Testimonials />
          <Title className={styles.caseStudiesContent__topTitle}>Case Studies</Title>
          <Container type="mobile" className="d-flex justify-content-center">
            <Text className={styles.caseStudiesContent__subtitleContainer}>{description}</Text>
          </Container>
          <Container type="mobile" className="d-flex justify-content-center testimonials-cta">
            <Button
              type="action"
              className={styles.testimonials__testimonialsCta}
              href="https://start.deadlinefunnel.com/create-monthly"
              target="_blank"
              >
              Try It Free
            </Button>
          </Container>
          <div className="row">
            {
              caseStudies.map((caseStudy, index) => (
                <div key={index} className="col-xl-4 col-lg-4 col-6 d-flex justify-content-center">
                  <a href={caseStudy.href} className={styles.caseStudy} target="_blank" rel="noopener noreferrer">
                    <div className="d-flex flex-column justify-content-between h-100">
                      <div>
                        <Image name={caseStudy.image} className="mb-4" />
                        <div className={`${styles.caseStudy__title} mb-1`}>{caseStudy.author}</div>
                        <div className={`${styles.caseStudy__subtitle} mb-3`}>{caseStudy.occupation}</div>
                        <Text className={`${styles.caseStudy__text} pr-4`}>{caseStudy.description}</Text>
                      </div>
                      <LearnMoreLink href={caseStudy.href} className={styles.caseStudy__link} text="Read Case Study" />
                    </div>
                  </a>
                </div>
              ))
            }
          </div>
        </Container>
      </Container>
    </div>
  )
};

export default CaseStudiesContent;